import { isNotNil } from '@iheartradio/web.utilities';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { clsx } from 'clsx/lite';
import { prop } from 'remeda';
import type { Simplify } from 'type-fest';

import {
  type RainbowSprinkles,
  rainbowSprinkles,
} from '../../rainbow-sprinkles.css.js';
import type { ElementProps } from '../../types.js';
import { Icon } from '../icon.js';
import { circleStyle, loadingStyle, rotate, strokeVar } from './loading.css.js';

export type LoadingProps = Simplify<
  Omit<ElementProps<typeof Icon>, 'children'> &
    RainbowSprinkles & { [k: `data-${string}`]: boolean }
>;

export function Loading({
  strokeWidth = 2,
  className: _className,
  stroke,
  ...props
}: LoadingProps) {
  const isGrid = isNotNil(prop(props, 'data-grid'));

  const { className, style, otherProps } = rainbowSprinkles({
    animationName: rotate,
    animationDuration: '2000ms',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    strokeWidth,
    ...(isGrid ?
      {
        position: 'absolute',
        left: `calc(50cqw${!Number.isNaN(Number(props.size)) ? ` - ${Number(props.size) / 2}px` : ''})`,
        top: `calc(35dvh${!Number.isNaN(Number(props.size)) ? ` - ${Number(props.size) / 2}px` : ''})`,
        zIndex: 10,
      }
    : {}),
    ...props,
  });

  return (
    <Icon
      aria-label="Loading"
      className={clsx(loadingStyle, className, _className)}
      fill="none"
      style={style}
      {...otherProps}
    >
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle
          className={circleStyle}
          cx="50"
          cy="50"
          fill="transparent"
          r="47"
          style={assignInlineVars({ [strokeVar]: stroke })}
        />
      </svg>
    </Icon>
  );
}
